/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { useMemo } from 'react';

import { Column } from "common/components";
import Card, { CardProps } from "common/components/cards/Card";
import { useGetAllDomains } from 'domain/domain.hooks';
import WidgetIcon from 'widget/common/components/WidgetIcon';
import { BaseWidget } from "widget/widget.entities";
import styles from "./styles.module.scss";


export default function WidgetCard({
  className, value, onClick, children, ...rest
}: WidgetCardProps) {
  const { domains } = useGetAllDomains();
  const classes = useMemo(() => [styles.widgetCard, className].join(" "), [className]);
  const domain = useMemo(() => domains.find(d => d.id === value.domain), [domains, value])

  return (
    <Card
      interactive={onClick != null}
      className={classes}
      onClick={onClick}
      {...rest}>
      <div className={styles.widgetIcon}>
        <WidgetIcon
          type={value.type}
          color={value?.active && domain?.active ? '#1436BAbb' : '#bbb'}
        />
      </div>
      <Column fill>
        <h4>{value.name}</h4>
        <footer>{domain?.name}</footer>
        {children}
      </Column>
    </Card>
  );
}

export type WidgetCardProps = CardProps & {
  value: BaseWidget,
};