import { useIdentity } from 'auth/auth.hooks';
import { Tag } from 'common/components';
import { isNotSuccessful } from 'common/utils';
import { useGetAllDomains } from 'domain/domain.hooks';
import { useMemo } from 'react';
import { BiSolidWidget } from 'react-icons/bi';
import { LuLanguages } from 'react-icons/lu';
import { RiBodyScanFill } from 'react-icons/ri';
import { useLocation, useNavigate } from 'react-router-dom';

/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Menu, MenuDivider, MenuItem, MenuProps } from '@blueprintjs/core';

import styles from './styles.module.sass';

export default function AppSidebar({
  className, ...rest
}: AppSidebarProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const identity = useIdentity();

  const classes = useMemo(() => {
    const classes = [styles.appsidebar, className];
    return classes.join(" ").trim();
  }, [className]);

  const { domains, getAllDomainsState } = useGetAllDomains({
    filter: (d) => d.active,
  });

  return (
    <Menu className={classes} {...rest}>
      <MenuItem
        icon="globe"
        onClick={() => navigate('/domains')}
        selected={location.pathname.startsWith('/domains')}
        labelElement={identity?.subscription?.quota == null || isNotSuccessful(getAllDomainsState) ? null : (
          <Tag minimal
            intent={domains.length === identity.subscription!.quota.maxActiveDomains ? "warning" : "success"}>
            {domains.length} / {identity.subscription!.quota.maxActiveDomains}
          </Tag>
        )}
        text="My Domains" />
      <MenuItem
        icon={<BiSolidWidget size={19} style={{ paddingRight: 1 }} />}
        onClick={() => navigate('/widgets')}
        selected={location.pathname.startsWith('/widgets')}
        text="Widgets" />
      <MenuItem
        icon={<RiBodyScanFill size={19} style={{ paddingRight: 1 }} />}
        onClick={() => navigate('/bmi-submissions')}
        selected={location.pathname.startsWith('/bmi-submissions')}
        text="BMI Submissions" />
      {/* <MenuDivider title="Settings" />
      <MenuItem
        icon="endorsed"
        onClick={() => navigate('/subscription')}
        selected={location.pathname.startsWith('/subscription')}
        text="Subscription" />
      <MenuItem
        icon="user"
        text={["Profile"]}
        selected={location.pathname.startsWith('/profile')}
        onClick={() => navigate('/profile')}
      />
      <MenuItem
        icon='clean'
        text={["Refer a Friend"]}
        selected={location.pathname.startsWith('/refer-a-friend')}
        onClick={() => navigate('/refer-a-friend')}
      />
      <MenuItem
        icon={<LuLanguages size={17} style={{ marginLeft: 7, marginRight: 1 }} />}
        text="Language" >
        <MenuItem icon="tick" text="US" />
        <MenuItem icon="blank" text="UK" />
      </MenuItem>
      <MenuDivider title="Help" />
      <MenuItem
        icon="help"
        onClick={() => navigate('/help')}
        selected={location.pathname.startsWith('/help')}
        text="Help Article Library" />
      <MenuItem
        icon="bug"
        onClick={() => navigate('/bug')}
        selected={location.pathname.startsWith('/bug')}
        text="Report an Issue" /> */}

      <div className={styles.discoverBox} >
        <img src="/static/images/discover.png" alt="discover image" />
        <h1>Discover Patient10x Products</h1>
        <p>Explore our suite of innovative products at Patient10x and revolutionize your healthcare experience.</p>
        <a href="https://reviews.patient10x.com" target="_blank">Patient10x Reviews</a>
        <a href="https://forms.patient10x.com" target="_blank">Patient10x Forms</a>
      </div>
    </Menu>
  );
}

export type AppSidebarProps = Omit<MenuProps, "children"> & {

}