/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';

import { Alert, Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import {
  BackActivityIndicator, BackAsyncError, Button, Card, CardContent,
  CardHeader, Grid, PageContent, PageHeader, PageNotFound, Toast,
} from 'common/components';
import {
  AsyncStateBuilder, isLoading, useAppState,
  useFormData, useInitial, useStateErrors
} from 'common/utils';
import { UpdateBmiCalculator } from 'widget/bmi-calculator/actions/update-bmi-calculator.action';
import { BmiCalculatorWidget } from 'widget/bmi-calculator/bmi-calculator.entities';
import TheForm from 'widget/bmi-calculator/components/BmiCalculatorWidgetForm';
import { DeleteWidget } from 'widget/common/actions/delete-widget.action';
import IFramePublishDialog from 'widget/common/components/IFramePublishDialog';
import { useGetAllWidgets } from 'widget/widget.hooks';


export default function () {
  const { id } = useParams();
  const { t } = useTranslation();
  const initial = useInitial();
  const navigate = useNavigate();

  // store
  const { getAllWidgets, getAllWidgetsState, widget } = useGetAllWidgets<BmiCalculatorWidget>({
    find: widget => widget.id === id && widget.type === 'bmi-calculator',
  });
  const [saveState, dispatch] = useAppState((store) => store.widget.save);
  const [deleteState] = useAppState((store) => store.widget.delete);

  // state
  const [isConfirmingDeletion, confirmDeletion] = useState(false);
  const [isPushlishDialogVisible, setPublishDialogVisible] = useState(false);

  // form data
  const { formData, onChange, formErrors, changes, setFormErrors } =
    useFormData<BmiCalculatorWidget>(widget, {} as any, [widget]);
  useStateErrors(saveState, setFormErrors);

  // effects
  useEffect(() => {
    if (!initial) {
      if (deleteState.value) {
        Toast.showSuccess({ message: "Widget deleted" });
        navigate('/widgets');
      } else if (deleteState.error) {
        Toast.showAsyncError(t, deleteState.error);
      }
    }
  }, [deleteState]);

  return (
    <>
      <PageHeader
        title={widget?.name || ''}
        backButtonPath="/widgets">
        <Button
          text="Save"
          intent="success"
          onClick={() => dispatch(UpdateBmiCalculator(id!, changes))}
          hidden={id === 'new' || _.isEmpty(changes)}
          loading={isLoading(saveState)}
        />
        <Button
          text="Publish"
          intent="primary"
          onClick={() => setPublishDialogVisible(true)}
          hidden={widget?.id == null || !_.isEmpty(changes)}
        />
        <Popover2
          placement="bottom-end"
          interactionKind="click"
          content={
            <Menu>
              <MenuItem
                icon="trash"
                text="Delete"
                intent='danger'
                hidden={widget == null}
                disabled={widget == null}
                onClick={() => confirmDeletion(true)} />
            </Menu>
          }>
          <Button minimal rightIcon="menu" />
        </Popover2>
      </PageHeader>
      <PageContent>
        <AsyncStateBuilder state={getAllWidgetsState}
          onLoading={() => <BackActivityIndicator tall />}
          onFailed={(error) => <BackAsyncError error={error} onTryAgain={getAllWidgets} />}
          onValue={() => {
            if (!widget)
              return <PageNotFound />
            return (
              <>
                <Grid md={2} xs={1} gap={20}>
                  <Card>
                    <CardHeader title={["General"]} />
                    <CardContent>
                      <TheForm
                        value={formData}
                        errors={formErrors}
                        readOnly={isLoading(saveState)}
                        onChange={onChange}
                        hiddenFields={[
                          "accentColor",
                          "ctaButtonColor",
                          "logoUrl",
                          "headingText",
                          "descriptionMarkdown",
                          "thankYouButtonText",
                          "thankYouButtonUrl",
                          "popup",
                          "popupButtonText",
                        ]}
                      />
                    </CardContent>
                  </Card>
                  <Card>
                    <CardHeader title={["Customization"]} />
                    <CardContent>
                      <TheForm
                        value={formData}
                        errors={formErrors}
                        readOnly={isLoading(saveState)}
                        onChange={onChange}
                        visibleFields={[
                          "accentColor",
                          "ctaButtonColor",
                          "logoUrl",
                          "headingText",
                          "descriptionMarkdown",
                          "thankYouButtonText",
                          "thankYouButtonUrl",
                          "popup",
                          "popupButtonText",
                        ]}
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Card style={{ marginTop: 20 }}>
                  <CardHeader title={["Preview"]} />
                  <iframe
                    width="100%"
                    height={widget.popup ? "100px" : "722px"}
                    frameBorder="0"
                    style={widget.popup ? { padding: '20px 20vw' } : {}}
                    src={`${process.env.REACT_APP_API_URL}/widgets/bmi-calculator/${widget.id}-v${widget.version}.html`}>
                  </iframe>
                </Card>
              </>
            );
          }} />
        <Alert
          isOpen={isConfirmingDeletion}
          confirmButtonText='Delete'
          cancelButtonText='Nope'
          icon="trash"
          intent="danger"
          loading={isLoading(deleteState)}
          onCancel={() => confirmDeletion(false)}
          onConfirm={() => dispatch(DeleteWidget(id!))}>
          <p>Delete this widget?</p>
        </Alert>
        {widget &&
          <IFramePublishDialog
            widget={widget!}
            isOpen={isPushlishDialogVisible}
            width={widget.popup ? '200px' : '100%'}
            height={widget.popup ? '60px' : '722px'}
            onClose={() => setPublishDialogVisible(false)} />}
      </PageContent>
    </>
  );
}
