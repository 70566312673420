/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';

import { Alert, Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import {
  BackActivityIndicator, BackAsyncError, Button, Card, CardContent,
  CardHeader, Column, Grid, PageContent, PageHeader, PageNotFound,
  Toast,
} from 'common/components';
import {
  AsyncStateBuilder, isLoading,
  useAppState,
  useFormData, useInitial, useStateErrors
} from 'common/utils';
import { UpdateAdaWidget } from 'widget/ada/actions/update-ada-widget.action';
import { AdaWidget } from 'widget/ada/ada-widget.entities';
import AdaWidgetForm from 'widget/ada/components/AdaWidgetForm';
import AdaWidgetProfilesForm from 'widget/ada/components/AdaWidgetProfilesForm';
import AdaWidgetStylesForm from 'widget/ada/components/AdaWidgetStylesForm';
import { DeleteWidget } from 'widget/common/actions/delete-widget.action';
import PublishDialog from 'widget/common/components/PublishDialog';
import { useGetAllWidgets } from 'widget/widget.hooks';


export default function () {
  const { id } = useParams();
  const { t } = useTranslation();
  const initial = useInitial();
  const navigate = useNavigate();

  // store
  const { getAllWidgets, getAllWidgetsState, widget } = useGetAllWidgets<AdaWidget>({
    find: widget => widget.id === id && widget.type === 'ada',
  });
  const [saveState, dispatch] = useAppState((store) => store.widget.save);
  const [deleteState] = useAppState((store) => store.widget.delete);

  // state
  const [isConfirmingDeletion, confirmDeletion] = useState(false);
  const [isPushlishDialogVisible, setPublishDialogVisible] = useState(false);

  // form data
  const { formData, onChange, formErrors, changes, setFormErrors } =
    useFormData<AdaWidget>(widget, {} as any, [widget]);
  useStateErrors(saveState, setFormErrors);

  // effects
  useEffect(() => {
    if (!initial) {
      if (deleteState.value) {
        Toast.showSuccess({ message: "Widget deleted" });
        navigate('/widgets');
      } else if (deleteState.error) {
        Toast.showAsyncError(t, deleteState.error);
      }
    }
  }, [deleteState]);

  useEffect(() => {
    function clearPreviewWidget() {
      let script = document.getElementById("preview_script") as HTMLScriptElement;
      if (script) {
        document.getElementById("ada_styles")?.remove();
        document.getElementById("ada_script")?.remove();
        document.getElementById("mdp-readabler-popup-box")?.remove();
        document.getElementById("mdp-readabler-trigger-button")?.parentElement?.remove();;
        document.getElementById("mdp-readabler-sidebar")?.remove();
        document.getElementById("mdp-readabler-keyboard-box")?.remove();
        script.remove();
      }
    }
    if (widget) {
      clearPreviewWidget();
      const script = document.createElement("script");
      script.id = "preview_script";
      script.src = `${process.env.REACT_APP_API_URL}/widgets/ada/${widget.id}-v${widget.version}.js`;
      script.async = true;
      document.body.appendChild(script);
    }
    return clearPreviewWidget;
  }, [widget?.version]);

  return (
    <>
      <PageHeader
        title={widget?.name || ''}
        backButtonPath="/widgets">
        <Button
          text="Save"
          intent="success"
          onClick={() => dispatch(UpdateAdaWidget(id!, changes))}
          hidden={id === 'new' || _.isEmpty(changes)}
          loading={isLoading(saveState)}
        />
        <Button
          text="Publish"
          intent="primary"
          onClick={() => setPublishDialogVisible(true)}
          hidden={widget?.id == null || !_.isEmpty(changes)}
        />
        <Popover2
          placement="bottom-end"
          interactionKind="click"
          content={
            <Menu>
              <MenuItem
                icon="trash"
                text="Delete"
                intent='danger'
                hidden={widget == null}
                disabled={widget == null}
                onClick={() => confirmDeletion(true)} />
            </Menu>
          }>
          <Button minimal rightIcon="menu" />
        </Popover2>
      </PageHeader>
      <PageContent>
        <AsyncStateBuilder state={getAllWidgetsState}
          onLoading={() => <BackActivityIndicator tall />}
          onFailed={(error) => <BackAsyncError error={error} onTryAgain={getAllWidgets} />}
          onValue={() => {
            if (!widget)
              return <PageNotFound />
            return (
              <Grid md={2} xs={1} gap={20}>
                <Column gap={20}>
                  <Card>
                    <CardHeader title={["General"]} />
                    <CardContent>
                      <AdaWidgetForm
                        value={formData}
                        errors={formErrors}
                        readOnly={isLoading(saveState)}
                        onChange={onChange}
                      />
                    </CardContent>
                  </Card>
                  <Card>
                    <CardHeader title={["Profiles"]} />
                    <CardContent>
                      <AdaWidgetProfilesForm
                        name='profiles'
                        value={formData?.profiles}
                        errors={formErrors?.profiles}
                        readOnly={isLoading(saveState)}
                        onChange={onChange}
                      />
                    </CardContent>
                  </Card>
                </Column>
                <Card>
                  <CardHeader title={["Apperance"]} />
                  <CardContent>
                    <AdaWidgetStylesForm
                      name='styles'
                      value={formData?.styles}
                      errors={formErrors?.styles}
                      readOnly={isLoading(saveState)}
                      onChange={onChange}
                    />
                  </CardContent>
                </Card>
              </Grid>
            );
          }} />
        <Alert
          isOpen={isConfirmingDeletion}
          confirmButtonText='Delete'
          cancelButtonText='Nope'
          icon="trash"
          intent="danger"
          loading={isLoading(deleteState)}
          onCancel={() => confirmDeletion(false)}
          onConfirm={() => dispatch(DeleteWidget(id!))}>
          <p>Delete this widget?</p>
        </Alert>
        {widget &&
          <PublishDialog
            widget={widget!}
            isOpen={isPushlishDialogVisible}
            onClose={() => setPublishDialogVisible(false)} />}
      </PageContent>
    </>
  );
}
