/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from "lodash";
import React, { useMemo, useState } from "react";

import { Collapse, FormGroup, FormGroupProps } from "@blueprintjs/core";
import { useTranslation } from "react-i18next";

import FormLabel from "common/components/form/Label";
import styles from "./styles.module.scss";
import { Button } from "common/components/buttons";


export default function FormField({
  className, hidden, label = '', error, children, actions,
  borderless, collapsed, ...rest
}: FormFieldProps) {
  if (hidden) return null;

  const { t } = useTranslation();

  const [isCollapsed, setCollapsed] = useState(collapsed);

  const errorText = useMemo(() =>
    _.isArray(error) ? t(error[0], error[2], error[1]) : _.isString(error) ? error : null,
    [error])
  const labelText = useMemo(() => {
    const labelText = _.isArray(label) ? t(label[0], label[2], label[1]) : label;
    return [labelText, (_.isEmpty(labelText) || _.isEmpty(errorText) ? '' : ': '), errorText].join('');
  }, [label, errorText]);

  const classes = useMemo(() => {
    const items = [styles.formField, className];
    if (borderless) items.push(styles.borderless);
    if (errorText) items.push("error");
    return items.join(' ');
  }, [className, borderless, errorText]);

  return (
    <FormGroup
      className={classes}
      label={
        <FormLabel
          text={labelText}
          actions={[
            actions,
            collapsed == null ? undefined : (
              <Button
                key="collapse-action"
                small
                minimal
                icon={isCollapsed ? "chevron-down" : "chevron-up"}
                onClick={() => setCollapsed(!isCollapsed)}
              />
            )
          ]}
        />
      } {...rest} >
      <Collapse isOpen={!isCollapsed}>
        {children}
      </Collapse>
    </FormGroup>
  );
}

export type FormFieldProps = FormGroupProps & {
  hidden?: boolean,
  label?: string | [string, object?, string?],
  error?: string | [string, object?, string?],
  children?: React.ReactNode,
  actions?: React.ReactNode,
  borderless?: boolean;
  collapsed?: boolean;
}