/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';
import React, { useMemo } from "react";
import { useTranslation } from 'react-i18next';

import styles from "./styles.module.sass";


export default function CardHeader({
  className, title, right, rightProps, ...rest
}: CardHeaderProps) {
  const { t } = useTranslation();
  const classes = useMemo(() => {
    const cls = [styles.cardheader];
    if (className) cls.push(className);
    return cls.join(" ").trim();
  }, [className]);
  const titleText = _.isArray(title) ? t(title[0], title[2], title[1]) : title;

  return (
    <div className={classes} {...rest}>
      <h4>{titleText}</h4>
      <div className='right' {...rightProps}>{right}</div>
    </div>
  );
}

export type CardHeaderProps = Omit<React.ComponentPropsWithRef<"div">, "title"> & {
  title?: string | [string, object?, string?],
  right?: React.ReactNode,
  rightProps?: React.ComponentPropsWithRef<"div">,
}
